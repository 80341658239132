<template>
  <b-container fluid>
    <ResourceList
      create-to="dash.supplies.create" resource="supply" resource-plural="supplies" soft-deletes title="Supplies" :has-buttons="false" :can-select-many="false"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill p-3">
          <div class="d-flex flex-column flex-fill align-items-start justify-content-center">
            <b-container fluid class="p-0">
              <b-row>
                <b-col cols="7" class="d-flex flex-fill flex-row justify-content-center align-items-center">
                  <p class="h5 flex-fill mb-2 text-primary"><router-link class="text-dark" :to="{name: 'dash.supplies.update', params: { id: item.id }}">{{ item.name }}</router-link></p>
                </b-col>
                <b-col cols="5" class="d-flex align-items-center justify-content-end">
                  <p class="mb-0 text-muted">Expected: <FriendlyDate :date="item.expected_date"/></p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="flex-row">
                    <b-button class="mb-0 mr-1" size="sm" variant="secondary" v-b-popover.hover.bottom="'View more information about this customer.'" :to="{name: 'dash.supplies.mark-arrivals', params: { id: item.id }}"><b-icon icon="pen" /> Mark Stock as Arrived</b-button>
                    <b-button class="mb-0 mr-0" size="sm" variant="primary" v-b-popover.hover.bottom="'View more information about this customer.'" :to="{name: 'dash.supplies.view', params: { id: item.id }}"><b-icon icon="person" /> View</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchMany(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('supplies', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('supplies', ['batchDelete', 'fetchMany']),
    async onDeleteMany ({ ids, modalId }) {
      await this.batchDelete({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({ ...this.$route.query, query })
      }
    },
  }
}
</script>

<style>

</style>

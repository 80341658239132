<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Supply</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'name')" v-model="supply.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Superior Spa Batch">
                <b-form-input :disabled="isSaving" type="text" v-model="supply.superior_batch" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Expected Date" :invalid-feedback="validationInvalidFeedback(errors.errors, 'expected_date')">
                <b-form-datepicker :disabled="isSaving" :state="validationState(errors.errors, 'expected_date')" v-model="supply.expected_date" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('products')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['products'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Products</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['products']">
                  <b-col cols="12" v-if="!supply.products.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no products selected.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="supply.products.length" class="p-4">
                    <product-search :error-feed="errors.errors" :error-key="'products.'+ind+'.product_id'" class="mb-3" @delete="rmProduct(ind)" show-delete type="product" v-model="supply.products[ind].product" @input="productSelected(ind)" :key="'product_' + supply.products[ind].product_id" v-for="(product, ind) in supply.products">
                      <template #selected-inline-form-area>
                          <b-row>
                            <b-col cols="12" class>
                              <b-form-input :disabled="isSaving" placeholder="Quantity" type="text" :state="validationState(errors.errors, 'products.'+ind+'.quantity')" v-model="supply.products[ind].quantity" :key="'quantity_' + supply.products[ind].product_id" />
                            </b-col>
                          </b-row>
                      </template>
                    </product-search>
                  </b-col>
                  <b-col cols="12">
                    <b-button block @click.prevent="addProduct" variant="primary"><fa-icon icon="plus" /> Add Product</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import ProductSearch from '../../components/ProductSearch';
import StorageLocationSearch from '../../components/StorageLocationSearch';

export default {
  components: { ProductSearch, StorageLocationSearch },
  mixins: [validation,momentMixin],
  created () {
    if (this.$route.params.id) {
      this.fetch({ id: this.$route.params.id, group: true })
    }
  },
  computed: {
    ...mapGetters('supplies', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  data () {
    return {
      collapsed: {
        products: !this.$route.params.id,
      },
      correspondenceFilter: null,
      upcomingCorrespondenceFilter: null,
      types: [
        { text: 'Simple Supply', value: 'simple' },
        { text: 'Grouped Supply', value: 'grouped' },
        { text: 'External/Affiliate Supply', value: 'external' },
        { text: 'Variable Supply', value: 'variable' }
      ],
      supply: {
        id: null,
        name: null,
        superior_batch: null,
        expected_date: null,
        storage_location: null,
        products: [],
      },
    }
  },
  methods: {
    ...mapActions('supplies', ['fetch', 'save']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    submit () {
      let data = {
        id: this.$route.params.id,
        name: this.supply.name,
        superior_batch: this.supply.superior_batch,
        expected_date: this.supply.expected_date,
        products: this.supply.products,
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status))
        this.$router.push({
          name: 'dash.supplies.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Supply #${response.data.id}`,
              subtitle: response.data.expected_date ? `Expected @ ${ this.moment(response.data.expected_date).format('DD/MM/YYYY') }` : 'Unknown Delivery Date.',
              text: `The supply has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },

    productSelected(ind) {
      this.supply.products[ind].product_id = this.supply.products[ind].product.id;
      this.supply.products[ind].cost_price = this.supply.products[ind].product.cost_price;

      // If product already exists, find it and add to the existing row.
      var index = this.supply.products.findIndex(obj => obj.product.id === this.supply.products[ind].product.id);
      if(index !== -1 && index !== ind){
        this.supply.products[index].quantity += 1;
        this.supply.products.splice((this.supply.products.length-1), 1);
      }
    },

    storageLocationSelected(ind) {
      this.supply.products[ind].storage_location_id = this.supply.products[ind].storage_location.id;
    },

    addProduct() {
      this.supply.products.push({
        id: null,
        product_id: null,
        cost_price: null,
        miklan: null,
        sea_ref: null,
        expected_date: null,
        storage_location_id: null,
        storage_location: { id: null },
        product: null,
        quantity: 1,
      });
    },

    rmProduct(ind) {
      this.supply.products.splice(ind, 1);
    },
  },
  watch: {
    single () {
      this.supply = this.single
    }
  }
}
</script>

<style>

</style>

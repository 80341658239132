export const SUPPLIES_BATCH_DELETE_REQUEST = 'SUPPLIES_BATCH_DELETE_REQUEST'
export const SUPPLIES_BATCH_DELETE_SUCCESS = 'SUPPLIES_BATCH_DELETE_SUCCESS'
export const SUPPLIES_BATCH_DELETE_FAILURE = 'SUPPLIES_BATCH_DELETE_FAILURE'

export const SUPPLIES_FETCH_MANY_REQUEST = 'SUPPLIES_FETCH_MANY_REQUEST'
export const SUPPLIES_FETCH_MANY_SUCCESS = 'SUPPLIES_FETCH_MANY_SUCCESS'
export const SUPPLIES_FETCH_MANY_FAILURE = 'SUPPLIES_FETCH_MANY_FAILURE'

export const SUPPLIES_FETCH_REQUEST = 'SUPPLIES_FETCH_REQUEST'
export const SUPPLIES_FETCH_SUCCESS = 'SUPPLIES_FETCH_SUCCESS'
export const SUPPLIES_FETCH_FAILURE = 'SUPPLIES_FETCH_FAILURE'

export const SUPPLIES_SAVE_REQUEST = 'SUPPLIES_SAVE_REQUEST'
export const SUPPLIES_SAVE_SUCCESS = 'SUPPLIES_SAVE_SUCCESS'
export const SUPPLIES_SAVE_FAILURE = 'SUPPLIES_SAVE_FAILURE'

export const EMPTY_SUPPLIES = 'EMPTY_SUPPLIES'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

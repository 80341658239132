<template>
  <router-view />
</template>

<script>
import supplyModule from '../../store/supplies'
import productModule from '../../store/products'
import storageLocationModule from '../../store/storage-locations'

export default {
  beforeCreate () {
    this.$store.registerModule('supplies', supplyModule)
    this.$store.registerModule('products', productModule)
    this.$store.registerModule('storage-locations', storageLocationModule)
  },
  destroyed () {
    this.$store.unregisterModule('supplies')
    this.$store.unregisterModule('products')
    this.$store.unregisterModule('storage-locations')
  }
}
</script>

import {
  STORAGE_LOCATIONS_BATCH_DELETE_REQUEST,
  STORAGE_LOCATIONS_BATCH_DELETE_SUCCESS,
  STORAGE_LOCATIONS_BATCH_DELETE_FAILURE,
  STORAGE_LOCATIONS_FETCH_MANY_REQUEST,
  STORAGE_LOCATIONS_FETCH_MANY_SUCCESS,
  STORAGE_LOCATIONS_FETCH_MANY_FAILURE,
  STORAGE_LOCATIONS_FETCH_REQUEST,
  STORAGE_LOCATIONS_FETCH_SUCCESS,
  STORAGE_LOCATIONS_FETCH_FAILURE,
  STORAGE_LOCATIONS_SAVE_REQUEST,
  STORAGE_LOCATIONS_SAVE_SUCCESS,
  STORAGE_LOCATIONS_SAVE_FAILURE,
  EMPTY_STORAGE_LOCATIONS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(STORAGE_LOCATIONS_BATCH_DELETE_REQUEST)
    return window.axios.post('/storage-locations', { _method: 'DELETE', ids }).then(response => {
      commit(STORAGE_LOCATIONS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(STORAGE_LOCATIONS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(STORAGE_LOCATIONS_FETCH_MANY_REQUEST)
    return window.axios.get('/storage-locations', { params: { query, page } }).then(response => {
      commit(STORAGE_LOCATIONS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(STORAGE_LOCATIONS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(STORAGE_LOCATIONS_FETCH_REQUEST)
    return window.axios.get(`/storage-locations/${id}`).then(response => {
      commit(STORAGE_LOCATIONS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(STORAGE_LOCATIONS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, storageLocation) {
    const path = storageLocation.id ? `/storage-locations/${storageLocation.id}` : '/storage-locations'
    const method = storageLocation.id ? 'put' : 'post'

    commit(STORAGE_LOCATIONS_SAVE_REQUEST)
    return window.axios[method](path, storageLocation).then(response => {
      commit(STORAGE_LOCATIONS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(STORAGE_LOCATIONS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(STORAGE_LOCATIONS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_STORAGE_LOCATIONS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}

<template>
  <resource-search @delete="() => this.$emit('delete')" :isSlim="slim" @input="id => this.$emit('input', id)" module="storage-locations" :error-feed="errorFeed" :error-key="errorKey" :show-delete="showDelete" :wait-for-input="waitForInput" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
      <div class="d-flex flex-column h-100 w-100 justify-content-center">
        <p class="font-weight-bold mb-0">{{ result.name }}</p>
      </div>
    </template>

    <template #selected="{ selected }">
      <div class="d-flex flex-column h-100 w-100 justify-content-center">
        <p class="font-weight-bold mb-0 ml-3">{{ selected.name }}</p>
      </div>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
  },
  computed: {
    extraFetchParams() {
      if (this.type) {
        return { type: this.type };
      }

      return {};
    }
  }
}
</script>

<style>

</style>
<template>
  <router-view />
</template>

<script>
import supplyProductModule from '../../store/supply-products'
import productModule from '../../store/products'
import storageLocationModule from '../../store/storage-locations'

export default {
  beforeCreate () {
    this.$store.registerModule('supply-products', supplyProductModule)
    this.$store.registerModule('products', productModule)
    this.$store.registerModule('storage-locations', storageLocationModule)
  },
  destroyed () {
    this.$store.unregisterModule('supply-products')
    this.$store.unregisterModule('products')
    this.$store.unregisterModule('storage-locations')
  }
}
</script>

<template>
  <b-container fluid>
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Stock Approval #{{ $route.params.id }}</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col>
            <b-card title="Supply Details" class="mb-3">
              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label="Name">
                    <b-form-input :disabled="true" type="text" v-model="supply.name" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Superior Spa Batch">
                    <b-form-input :disabled="true" type="text" :value="supply.superior_batch" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Expected Date">
                    <b-form-input :disabled="true" type="text" :value="formatDate(supply.expected_date)" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                  <b-col cols="12">
                    <b-card>
                        <b-row>
                        <b-col class="d-flex flex-row" cols="12">
                            <div class="d-flex flex-column flex-fill">
                            <p class="h4 font-weight-bold mb-0">Supply Products</p>
                            </div>
                        </b-col>
                        </b-row>
                        <b-row>
                        <b-col cols="12" v-if="!supply.products.length" class="p-4">
                            <b-row>
                            <b-col class="text-center" cols="12">
                                <b-card class="py-3 text-center">
                                <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                                <p class="font-weight-normal h5 mb-0 text-dark">There are no products in this supply.</p>
                                </b-card>
                            </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" v-if="supply.products.length" class="p-4">
                            <b-table-simple striped hover>
                            <b-thead>
                                <b-tr>
                                <b-th>Name</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(product, ind) in this.supply.products" :key="`product${ind}`">
                                <b-td>
                                    <p class="mb-0">{{ product.product.name }}</p>
                                    <small class="text-muted">{{ product.superior_container_number }}</small>
                                </b-td>
                                </b-tr>
                            </b-tbody>
                            </b-table-simple>
                        </b-col>
                        </b-row>
                    </b-card>
                  </b-col>
              </b-row>
            </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-5">
              <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                      <b-button block variant="secondary"
                          @click="approveSupply()"
                          >Approve</b-button>
                    </b-col>
                    <b-col cols="6">
                      <b-button block variant="danger"
                          @click="disapproveSupply()"
                          >Disapprove</b-button>
                    </b-col>
                </b-row>
              </b-col>
          </b-row>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validation,momentMixin],
  created () {
    if (this.$route.params.id) {
      this.fetch({id: this.$route.params.id})
    }
  },
  computed: {
    ...mapGetters('supplies', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      supply: {
        id: null,
        name: null,
        expected_date: null,
        storage_location: null,
        products: [],
      },
    }
  },
  methods: {
    ...mapActions('supplies', ['fetch']),

    formatDate(date) {
      return this.moment(date).format('DD/MM/YYYY');
    },

    approveSupply() {
      window.axios.post(`/supplies/approve/${this.supply.id}`)
        .then(response => {
          if([ 200, 201 ].includes(response.status)) this.$router.push({
            name: 'dash.supplies.awaiting-approval',
            params: {
              notifications: [{
                variant: 'success',
                title: `Supply #${response.data.id}`,
                text: `The supply has been approved successfully.`,
                timeout: 2500
              }]
            }
          })
        })
    },

    disapproveSupply() {
      window.axios.post(`/supplies/disapprove/${this.supply.id}`)
        .then(response => {
          if([ 200, 201 ].includes(response.status)) this.$router.push({
            name: 'dash.supplies.awaiting-approval',
            params: {
              notifications: [{
                variant: 'success',
                title: `Supply #${response.data.id}`,
                text: `The supply has been disapproved successfully.`,
                timeout: 2500
              }]
            }
          })
        })
    }
  },
  watch: {
    single () {
      this.supply = this.single
    }
  }
}
</script>

<style>

</style>

<template>
  <b-container fluid>
    <b-row>
      <b-col cols="7" class="d-flex align-items-start">
        <h1 class="font-weight-bold mb-1">Stock</h1>
      </b-col>
      <b-col cols="5" class="d-flex flex-row justify-content-end">
        <b-button @click="bulkUpdateData.modal = true" variant="secondary" class="mr-2" :disabled="!computedAllowBulkUpdate">Bulk Update</b-button>
        <b-button @click="swapData.modal = true" variant="primary" class="mr-2" :disabled="!computedAllowSwap">Swap</b-button>
        <b-button @click="cancelData.modal = true" variant="danger" :disabled="!computedAllowBulkUpdate">Cancel</b-button>
      </b-col>
    </b-row>

    <b-row class="my-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col cols="12" class="mt-md-0 mt-3">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Search">
                    <b-form-input placeholder="Search..." v-model="searchFilters.filterQuery" @input="updateProductList"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Product">
                    <product-search slim @input="updateProductList" type="product" v-model="searchFilters.product"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <hr class="mt-0">
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label="Storage Location" class="mb-0">
                    <storage-location-search slim :wait-for-input="false" class="mb-3" type="storage-location" @input="updateProductList" v-model="searchFilters.storageLocation" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Stock Status" class="mb-md-0">
                    <b-form-select v-model="searchFilters.stockStatus" @change="updateProductList">
                      <b-form-select-option :value="null">All</b-form-select-option>
                      <b-form-select-option value="Arrived">Arrived</b-form-select-option>
                      <b-form-select-option value="Not Arrived">Not Arrived</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Assigned" class="mb-0">
                    <b-form-select v-model="searchFilters.assignedStatus" @change="updateProductList">
                      <b-form-select-option :value="null">All</b-form-select-option>
                      <b-form-select-option value="Assigned">Assigned</b-form-select-option>
                      <b-form-select-option value="Not Assigned">Not Assigned</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <hr class="mt-0">
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Expected By" class="mb-md-0">
                    <b-form-datepicker reset-button v-model="searchFilters.expectedBy" @input="updateProductList"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Installation Date" class="mb-0">
                    <b-form-datepicker reset-button v-model="searchFilters.installAt" @input="updateProductList"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body>
          
          <b-table-simple responsive>

            <b-thead>
              <b-tr>
                <b-th>Product Name</b-th>
                <b-th>Location</b-th>
                <b-th>Supply</b-th>
                <b-th>Superior Order Number</b-th>
                <b-th>Expected Date</b-th>
                <b-th>Arrived</b-th>
                <b-th>Order</b-th>
                <b-th>Order Date</b-th>
                <b-th>Install Date</b-th>
                <b-th>Action</b-th>
              </b-tr>
            </b-thead>

            <b-tbody v-if="!isLoading">
              <b-tr v-for="supplyProduct in data" :key="supplyProduct.id">
                <b-td>
                  <div class="d-flex flex-column">
                    <p class="mb-0">{{ supplyProduct.product.name }}</p>
                    <div>
                      <b-button variant="link" class="p-0 m-0" @click="noteData.modal = true; noteData.supplyProduct = supplyProduct">Notes</b-button>
                    </div>
                  </div>
                </b-td>
                <b-td>{{ supplyProduct.storage_location_id ? supplyProduct.storage_location.name : '-' }}</b-td>
                <b-td>{{ supplyProduct.supply.name }}</b-td>
                <b-td>{{ supplyProduct.superior_order_number ? supplyProduct.superior_order_number :  '-' }}</b-td>
                <b-td>{{ supplyProduct.expected_date ? moment(supplyProduct.expected_date).format('DD/MM/YYYY') : moment(supplyProduct.supply.expected_date).format('DD/MM/YYYY') }}</b-td>
                <b-td>{{ supplyProduct.arrived ? 'Arrived' : 'Not Arrived' }}</b-td>
                <b-td>
                  <router-link v-if="supplyProduct.order_id" v-b-popover.hover.lefttop="'View more information about this order.'" :to="{ name: 'dash.orders.view', params: { id: supplyProduct.order_id } }">
                    <p class="mb-0">Order #{{ supplyProduct.order_id }}</p>
                    <small v-if="supplyProduct.order && supplyProduct.order.customer">{{ supplyProduct.order.customer.forename + ' ' + supplyProduct.order.customer.surname }}</small>
                  </router-link>
                  <div v-else>
                    <p class="mb-0">Unnassigned</p>
                    <router-link class="text-secondary" :to="{name: 'dash.orders.create', params: { supply_product: supplyProduct }}">Start Order</router-link>  
                  </div>
                </b-td>
                <b-td>
                  <p v-if="supplyProduct.order_item_id">{{ supplyProduct.item.install_at ? moment(supplyProduct.item.install_at).format('DD/MM/YYYY') : 'No Installation Date Set' }}</p>
                  <p v-else>Unassigned</p>
                </b-td>
                <b-td>
                  <p v-if="supplyProduct.order_item_id">{{ supplyProduct.item.install_at ? moment(supplyProduct.item.install_at).format('DD/MM/YYYY') : 'No Installation Date Set' }}</p>
                  <p v-else>Unassigned</p>
                </b-td>
                <b-td>
                  <b-container class="flex align-items-center justify-content-center">
                    <b-form-checkbox :checked="computedSelectedSupplyProductIds.includes(supplyProduct.id)" @change="toggleSelected(supplyProduct)"></b-form-checkbox>
                  </b-container>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="isLoading">
              <b-tr>
                <b-td colspan="10">
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-center align-items-center">
                      <b-spinner variant="secondary"></b-spinner>    
                    </b-col>
                  </b-row>
                </b-td>
              </b-tr>
            </b-tbody>

          </b-table-simple>
        </b-card>

        <b-row class="my-2" v-if="data && !isLoading && pagination.per < pagination.total">
          <b-col cols="12">
            <b-pagination @change="onPaginate" :per-page="pagination.per" :total-rows="pagination.total" v-model="pagination.current"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal id="bulk-modal" centered size="lg" title="Bulk Update" v-model="bulkUpdateData.modal">
      <template #default>
        <b-container v-if="bulkUpdateData.modal">
          <b-row>
            <b-col cols="12" class="py-3">

              <b-form-group label="Arrived">
                <b-form-select v-model="bulkUpdateData.arrived">
                  <b-form-select-option value="Arrived">Arrived</b-form-select-option>
                  <b-form-select-option value="Not Arrived">Not Arrived</b-form-select-option>
                  <b-form-select-option selected value="Leave the same">Leave the Same</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Location">
                <b-form-select v-model="bulkUpdateData.location">
                  <b-form-select-option value="Update">Update</b-form-select-option>
                  <b-form-select-option value="Leave the same">Leave the Same</b-form-select-option>
                  <b-form-select-option selected value="Unset">Unset</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group v-if="bulkUpdateData.location == 'Update'" label="Storage Location">
                <storage-location-search :wait-for-input="false" class="mb-3" type="storage-location" v-model="bulkUpdateData.storage_location" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template #modal-footer>
        <b-container v-if="bulkUpdateData.modal">
          <b-row>
            <b-col cols="12">
              <b-button variant="secondary" @click="performBulkUpdate">Perform Bulk Update</b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

    <b-modal id="swap-modal" centered size="lg" title="Bulk Update" v-model="swapData.modal">
      <template #default>
        <b-container v-if="swapData.modal">
          <b-row>
            <b-col cols="12" class="pt-3" v-if="computedSortByAssigned[0].order && computedSortByAssigned[0].order.customer">
              <p>{{ computedSortByAssigned[0].order.customer.forename + ' ' + computedSortByAssigned[0].order.customer.surname }} will be changed to expected {{ moment(computedSortByAssigned[1].expected_date).format('DD/MM/YYYY') }} instead of {{ moment(computedSortByAssigned[0].expected_date).format('DD/MM/YYYY') }}</p>
            </b-col>
            <b-col cols="12" class="pt-3" v-if="computedSortByAssigned[1].assigned_at && computedSortByAssigned[1].order && computedSortByAssigned[1].order.customer">
              <p>{{ computedSortByAssigned[1].order.customer.forename + ' ' + computedSortByAssigned[1].order.customer.surname }} will be changed to expected {{ moment(computedSortByAssigned[0].expected_date).format('DD/MM/YYYY') }} instead of {{ moment(computedSortByAssigned[1].expected_date).format('DD/MM/YYYY') }}</p>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template #modal-footer>
        <b-container v-if="swapData.modal">
          <b-row>
            <b-col cols="12">
              <b-button variant="secondary" @click="performSwap">Approve Swap</b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

    <b-modal id="cancel-modal" centered size="lg" title="Cancel Stock" v-model="cancelData.modal">
      <template #default>
        <b-container v-if="cancelData.modal">
          <b-row>
            <b-col cols="12">
              <p>This action will mark the following stock as no longer coming:</p>
              <b-card>
              <b-table-simple striped>
                <b-thead>
                  <b-tr>
                    <b-th>Supply</b-th>
                    <b-th>Product</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="supplyProduct in selectedSupplyProducts" :key="`cancellation-product-${supplyProduct.id}`">
                    <b-td>{{ supplyProduct.supply.name }}</b-td>
                    <b-td>{{ supplyProduct.product.name }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template #modal-footer>
        <b-container v-if="cancelData.modal">
          <b-row>
            <b-col cols="12">
              <b-button variant="primary" @click="performCancellation">Approve Cancellation</b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

    <b-modal id="note-modal" centered size="lg" title="Update Note" v-model="noteData.modal">
      <template #default>
        <b-container v-if="noteData.modal">
          <b-row>
            <b-col cols="12">
              <h4>Note</h4>
              <b-form-textarea :disabled="noteData.noteSaving" v-model="noteData.supplyProduct.notes"/>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template #modal-footer>
        <b-container v-if="noteData.modal">
          <b-row>
            <b-col cols="12">
              <b-button variant="primary" :disabled="noteData.noteSaving" @click="updateNote">
                <span v-if="!noteData.noteSaving">Save Changes</span>
                <b-spinner v-else/>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import StorageLocationSearch from '../../components/StorageLocationSearch'
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import ProductSearch from '../../components/ProductSearch'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    if (this.$route.params.product_id) {
      this.searchFilters.product.id = this.$route.params.product_id;
    }
    this.fetchAll({
      extraParams: {
        product: this.searchFilters.product.id,
        storageLocation: this.searchFilters.storageLocation.id,
        stockStatus: this.searchFilters.stockStatus,
        assignedStatus: this.searchFilters.assignedStatus,
        expectedBy: this.searchFilters.expectedBy,
        installAt: this.searchFilters.installAt,
      }
    })

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      switch(modalId) {
        case 'swap-modal': this.swapData.modal = false; break;
        case 'bulk-modal': this.bulkUpdateData.modal = false; break;
        case 'note-modal': this.noteData.modal = false; this.noteData.noteSaving = false; this.noteData.supplyProduct = null; break;
      }
    })
  },
  data: () => ({
    selectedSupplyProducts: [],

    searchFilters: {
      product: { id: null },
      filterQuery: null,
      storageLocation: { id: null },
      stockStatus: null,
      assignedStatus: null,
      expectedBy: null,
      installAt: null,
    },

    swapData: {
      modal: false,
    },

    cancelData: {
      modal: false,
    },

    bulkUpdateData: {
      modal: false,

      arrived: 'Leave the same',

      location: 'Leave the same',
      storage_location: { id: null },
    },

    noteData: {
      modal: false,
      supplyProduct: null,
      noteSaving: false
    },

  }),
  components: {
    FriendlyDate,
    ResourceList,
    ProductSearch,
    StorageLocationSearch
  },
  computed: {
    ...mapGetters('supply-products', ['data', 'isDeleting', 'isLoading', 'pagination']),

    computedDisplaySwapModal() { return this.swapData.modal },
    computedDisplayBulkUpdateModal() { return this.bulkUpdateData.modal },

    computedSelectedSupplyProductIds() {
      return this.selectedSupplyProducts.map(selectedSupplyProduct => {
        return selectedSupplyProduct.id
      })
    },

    computedSortByAssigned() {
      return [
        // Assigned Supply Products First.
        ...this.selectedSupplyProducts.filter(supplyProduct => {
          return (this.computedSelectedSupplyProductIds.includes(supplyProduct.id) && supplyProduct.assigned_at)
        }),
        // Unassigned Supply Products if exists
        ...this.selectedSupplyProducts.filter(supplyProduct => {
          return (this.computedSelectedSupplyProductIds.includes(supplyProduct.id) && !supplyProduct.assigned_at)
        }),
      ];
    },

    computedAllowSwap() {
      var allow = true;
      var oneAttached = false;
      var productId = null;
      this.computedSelectedSupplyProductIds.forEach(selectedSupplyProductId => {
        var supplyProduct = this.selectedSupplyProducts.find(supplyProduct => (supplyProduct.id === selectedSupplyProductId))
        var supplyProductId = supplyProduct.product_id
        if(supplyProduct.assigned_at) {
          oneAttached = true
        }
        if(productId == null) productId = supplyProductId
        if(productId != supplyProductId) {
          allow = false
        }
      })
      return (this.computedSelectedSupplyProductIds.length === 2) && allow && oneAttached
    },

    computedAllowBulkUpdate() { return (this.computedSelectedSupplyProductIds.length > 0) }
  },
  methods: {
    ...mapActions('supply-products', ['batchDelete', 'fetchAll']),

    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page }, params: { product_id: this.product.id } })
        await this.fetchAll({
          ...this.$route.query,
          query: this.searchFilters.filterQuery,
          page,
          extraParams: {
            product: this.searchFilters.product.id,
            storageLocation: this.searchFilters.storageLocation.id,
            stockStatus: this.searchFilters.stockStatus,
            assignedStatus: this.searchFilters.assignedStatus,
            expectedBy: this.searchFilters.expectedBy,
            installAt: this.searchFilters.installAt,
          }
        })
      }
    },

    updateProductList() {
      this.fetchAll({
        query: this.searchFilters.filterQuery,
        extraParams: {
          product: this.searchFilters.product.id,
          storageLocation: this.searchFilters.storageLocation.id,
          stockStatus: this.searchFilters.stockStatus,
          assignedStatus: this.searchFilters.assignedStatus,
          expectedBy: this.searchFilters.expectedBy,
          installAt: this.searchFilters.installAt,
        }
      });
    },

    toggleSelected(supplyProduct) {
      if(!this.computedSelectedSupplyProductIds.includes(supplyProduct.id)) {
        this.selectedSupplyProducts.push(supplyProduct)
        return
      }
      this.selectedSupplyProducts.splice(this.selectedSupplyProducts.findIndex(selectedSupplyProduct => {
        return (selectedSupplyProduct.id == supplyProduct.id) 
      }), 1)
    },

    performBulkUpdate() {
      if(
        (this.bulkUpdateData.location == 'Leave the same') ||
        (this.bulkUpdateData.location == 'Unset') ||
        (this.bulkUpdateData.location == 'Update' && this.bulkUpdateData.storage_location.id)
      ) {
        return window.axios.post('/supply-products/bulk-update', {
            ...this.bulkUpdateData,
            'supply_products': this.computedSelectedSupplyProductIds
          }).then(response => {
          if(response.data) {
            this.selectedSupplyProducts = []
            this.bulkUpdateData.modal = false
            this.fetchAll({
              ...this.$route.query,
              'query': this.searchFilters.filterQuery,
              'page': this.pagination.current,
              extraParams: {
                product: this.searchFilters.product.id,
                storageLocation: this.searchFilters.storageLocation.id,
                stockStatus: this.searchFilters.stockStatus,
                assignedStatus: this.searchFilters.assignedStatus,
                expectedBy: this.searchFilters.expectedBy,
                installAt: this.searchFilters.installAt,
              }
            })
          }
        })
      }
    },

    performSwap() {
      if(this.computedAllowSwap) {
        return window.axios.post('/supply-products/swap', {
            'supply_product_1': this.computedSelectedSupplyProductIds[0],
            'supply_product_2': this.computedSelectedSupplyProductIds[1],
          }).then(response => {
          if(response.data) {
            this.selectedSupplyProducts = []
            this.swapData.modal = false
            this.fetchAll({
              ...this.$route.query,
              'query': this.searchFilters.filterQuery,
              'page': this.pagination.current,
              extraParams: {
                product: this.searchFilters.product.id,
                storageLocation: this.searchFilters.storageLocation.id,
                stockStatus: this.searchFilters.stockStatus,
                assignedStatus: this.searchFilters.assignedStatus,
                expectedBy: this.searchFilters.expectedBy,
                installAt: this.searchFilters.installAt,
              }
            })
          }
        })
      }
    },

    performCancellation() {
      if(this.computedAllowBulkUpdate) {
        return window.axios.post('/supply-products/cancel', {
            'supply_products': this.computedSelectedSupplyProductIds
          }).then(response => {
          if(response.data) {
            this.selectedSupplyProducts = []
            this.cancelData.modal = false
            this.fetchAll({
              ...this.$route.query,
              'query': this.searchFilters.filterQuery,
              'page': this.pagination.current,
              extraParams: {
                product: this.searchFilters.product.id,
                storageLocation: this.searchFilters.storageLocation.id,
                stockStatus: this.searchFilters.stockStatus,
                assignedStatus: this.searchFilters.assignedStatus,
                expectedBy: this.searchFilters.expectedBy,
                installAt: this.searchFilters.installAt,
              }
            })
          }
        })
      }
    },

    updateNote() {
      this.noteData.noteSaving = true
      return window.axios.post(`/supply-products/update-note/${this.noteData.supplyProduct.id}`, {
        'note': this.noteData.supplyProduct.notes
      }).then(response => {
            this.noteData.noteSaving = false
            this.noteData.modal = false
            this.noteData.supplyProduct = null
            this.fetchAll({
              ...this.$route.query,
              'query': this.searchFilters.filterQuery,
              'page': this.pagination.current,
              extraParams: {
                product: this.searchFilters.product.id,
                storageLocation: this.searchFilters.storageLocation.id,
                stockStatus: this.searchFilters.stockStatus,
                assignedStatus: this.searchFilters.assignedStatus,
                expectedBy: this.searchFilters.expectedBy,
                installAt: this.searchFilters.installAt,
              }
            })
      })
    }
  },
  watch: {
    computedDisplaySwapModal(display) {
      if(!display) {
        //
      }
    },
    computedDisplayBulkUpdateModal(display) {
      if(!display) {
        this.bulkUpdateData = {
          modal: false,
          arrived: 'Leave the same',
          location: 'Leave the same',
          storage_location: { id: null },
        }
      }
    },
  }
}
</script>

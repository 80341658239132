export const STORAGE_LOCATIONS_BATCH_DELETE_REQUEST = 'STORAGE_LOCATIONS_BATCH_DELETE_REQUEST'
export const STORAGE_LOCATIONS_BATCH_DELETE_SUCCESS = 'STORAGE_LOCATIONS_BATCH_DELETE_SUCCESS'
export const STORAGE_LOCATIONS_BATCH_DELETE_FAILURE = 'STORAGE_LOCATIONS_BATCH_DELETE_FAILURE'

export const STORAGE_LOCATIONS_FETCH_MANY_REQUEST = 'STORAGE_LOCATIONS_FETCH_MANY_REQUEST'
export const STORAGE_LOCATIONS_FETCH_MANY_SUCCESS = 'STORAGE_LOCATIONS_FETCH_MANY_SUCCESS'
export const STORAGE_LOCATIONS_FETCH_MANY_FAILURE = 'STORAGE_LOCATIONS_FETCH_MANY_FAILURE'

export const STORAGE_LOCATIONS_FETCH_REQUEST = 'STORAGE_LOCATIONS_FETCH_REQUEST'
export const STORAGE_LOCATIONS_FETCH_SUCCESS = 'STORAGE_LOCATIONS_FETCH_SUCCESS'
export const STORAGE_LOCATIONS_FETCH_FAILURE = 'STORAGE_LOCATIONS_FETCH_FAILURE'

export const STORAGE_LOCATIONS_SAVE_REQUEST = 'STORAGE_LOCATIONS_SAVE_REQUEST'
export const STORAGE_LOCATIONS_SAVE_SUCCESS = 'STORAGE_LOCATIONS_SAVE_SUCCESS'
export const STORAGE_LOCATIONS_SAVE_FAILURE = 'STORAGE_LOCATIONS_SAVE_FAILURE'

export const EMPTY_STORAGE_LOCATIONS = 'EMPTY_STORAGE_LOCATIONS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

<template>
  <b-container fluid>
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Mark Supply #{{ $route.params.id }} Arrivals</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col>
            <b-card title="Supply Details" class="mb-3">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Name">
                    <b-form-input :disabled="true" type="text" v-model="supply.name" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group label="Expected Date">
                    <b-form-input :disabled="true" type="text" :value="formatDate(supply.expected_date)" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('products')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['products'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Supply Products</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['products']">
                  <b-col cols="12" v-if="!supply.products.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no products in this supply.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="supply.products.length" class="p-4">
                    <b-card no-body v-for="(product, productInd) in supply.products" :class="{ 'mt-3': productInd }" :key="`product${productInd}`">
                      <b-row class="pt-3 pl-3 pr-3" :class="{ 'pb-3': !product.arrived }">
                        <b-col cols="12" md="7" class="d-flex flex-column flex-fill align-items-start justify-content-center">
                          <h4>{{ product.product.name }}</h4>
                          <p class="text-muted mb-0">{{ product.order_id ? `Assigned to ${product.order.customer.forename} ${product.order.customer.surname}` : 'Not Assigned' }}</p>
                        </b-col>
                        <b-col cols="12" md="5" class="d-flex flex-row justify-content-end align-items-center">
                          <b-form-checkbox :id="`productarrived${productInd}`" :checked="isChecked(product.arrived)" @change="supply.products[productInd].arrived = !supply.products[productInd].arrived"/><label class="mb-0" :for="`productarrived${productInd}`">Product Arrived</label>
                        </b-col>
                      </b-row>
                      <b-row class="pl-3 pr-3" v-if="product.arrived">
                        <b-col cols="12">
                          <hr>
                        </b-col>
                      </b-row>
                      <b-row class="ml-3 mr-3 d-flex flex-column" v-if="product.arrived">
                        <b-row>
                          <b-col cols="12" md="12">
                            <b-form-group label="Storage Location" :invalid-feedback="validationInvalidFeedback(errors.errors, 'products.'+productInd+'.storage_location')">
                              <storage-location-search :error-feed="errors.errors" :error-key="'products.'+productInd+'.storage_location.id'" :wait-for-input="false" class="mb-3" type="storage-location" @input="storageLocationSelected(productInd)" v-model="supply.products[productInd].storage_location" />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" @click="markSupplyStockArrivals" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import StorageLocationSearch from '../../components/StorageLocationSearch';

export default {
  mixins: [validation,momentMixin],
  components: { StorageLocationSearch },
  created () {
    if (this.$route.params.id) {
      this.fetch({ id: this.$route.params.id, group: false })
    }
  },
  computed: {
    ...mapGetters('supplies', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      collapsed: {
        products: false,
      },

      supply: {
        id: null,
        name: null,
        expected_date: null,
        storage_location: null,
        products: [],
      },
    }
  },
  methods: {
    ...mapActions('supplies', ['fetch', 'markSupplyArrivals']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    isChecked(arrived) {
      return Boolean(arrived)
    },

    markSupplyStockArrivals() {
      this.markSupplyArrivals(this.supply).then(response => {
        //
      })
    },

    formatDate(date) {
      return this.moment(date).format('DD/MM/YYYY');
    },

    storageLocationSelected(ind) {
      this.supply.products[ind].storage_location_id = this.supply.products[ind].storage_location.id;
    },
  },
  watch: {
    single () {
      this.supply = this.single
    }
  }
}
</script>

<style>

</style>

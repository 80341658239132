import {
  SUPPLIES_BATCH_DELETE_REQUEST,
  SUPPLIES_BATCH_DELETE_SUCCESS,
  SUPPLIES_BATCH_DELETE_FAILURE,
  SUPPLIES_FETCH_MANY_REQUEST,
  SUPPLIES_FETCH_MANY_SUCCESS,
  SUPPLIES_FETCH_MANY_FAILURE,
  SUPPLIES_FETCH_REQUEST,
  SUPPLIES_FETCH_SUCCESS,
  SUPPLIES_FETCH_FAILURE,
  SUPPLIES_SAVE_REQUEST,
  SUPPLIES_SAVE_SUCCESS,
  SUPPLIES_SAVE_FAILURE,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(SUPPLIES_BATCH_DELETE_REQUEST)
    return window.axios.post('/supplies', { _method: 'DELETE', ids }).then(response => {
      commit(SUPPLIES_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLIES_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(SUPPLIES_FETCH_MANY_REQUEST)
    return window.axios.get('/supplies', { params: { query, page } }).then(response => {
      commit(SUPPLIES_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLIES_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchUnapproved ({ commit }, { query = '', page = 1 }) {
    commit(SUPPLIES_FETCH_MANY_REQUEST)
    return window.axios.get('/supplies/unapproved', { params: { query, page } }).then(response => {
      commit(SUPPLIES_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLIES_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, { id, group = false }) {
    commit(SUPPLIES_FETCH_REQUEST)
    return window.axios.get(`/supplies/${id}` + (group ? '?group=1' : '')).then(response => {
      commit(SUPPLIES_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SUPPLIES_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, container) {
    const path = container.id ? `/supplies/${container.id}` : '/supplies'
    const method = container.id ? 'put' : 'post'

    commit(SUPPLIES_SAVE_REQUEST)
    return window.axios[method](path, container).then(response => {
      commit(SUPPLIES_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SUPPLIES_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(SUPPLIES_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  markSupplyArrivals ({ commit }, container) {
    const path = `/supplies/${container.id}/mark-arrivals`

    commit(SUPPLIES_SAVE_REQUEST)
    return window.axios.post(path, container).then(response => {
      commit(SUPPLIES_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SUPPLIES_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(SUPPLIES_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_SUPPLIES);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
